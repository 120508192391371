<template>
  <div class="page-box">

    <!-- 邀请代码 -->
    <div class="code-box">
      <div class="sub-title">{{ $t("InviteCode") }}:</div>
      <div class="sub-num">{{ data.rec_code }}</div>
      <div v-clipboard:copy="data.rec_code" v-clipboard:success="onCopy" class="icon-copy">
        <img src="@/assets/images/copy-icon3.png" >
      </div>
    </div>

    <!-- 推荐 -->
    <div class="code-box">
      <div class="sub-title">{{ $t("Referrals") }}:</div>
      <div class="sub-num">{{ report.user_count  }}</div>
    </div>

    <!-- 我的推荐链接 -->
    <div class="share-link-box">
      <div class="share-link-title">{{ $t("My referral link") }}</div>
      <div class="share-link-input">
        <span class="link">{{ data.invite_url }}</span>
        <div v-clipboard:copy="data.invite_url" v-clipboard:success="onCopy" class="icon-copy">
          <img src="@/assets/images/copy-icon3.png">
        </div>
      </div>
    </div>

    <!-- 分享 -->
    <div class="code-box">
      <div class="sub-title">{{ $t("Share") }}:</div>
      <a href="javascript:void(0)" @click="onShare('telegram')" class="social-icons__item">
        <img src="@/assets/images/telegram2.png" alt="">
      </a>
      <a href="javascript:void(0)" @click="onShare('facebook')" class="social-icons__item">
        <img src="@/assets/images/facebook2.png" alt="">
      </a>
    </div>

    <!-- 充值返利 -->
    <div class="share-cover-box">
      <div class="share-cover-title">{{ $t("充值返利") }}</div>
      <div class="share-cover-desc">{{ $t("充值返利描述") }}</div>
    </div>

    <!-- 页面图片 -->
    <div class="page-img-box">
      <img src="@/assets/images/overview-img1.png" class="page-img1">
      <img src="@/assets/images/overview-img2.png" class="page-img2">
    </div>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
import { team_report_api, getRechargeRebate_api ,team_promotion_api} from "@/api/user";

export default {
  components:{svgIcon},
  data(){
    return{
      data:{},
      promotion:{},
      report: {
        agent_total_amount: 0,
        extension: 0,
        user_count: 0,
        lists: []
      },
    }
  },
  methods:{
    onCopy() {
      this.successTips('Copy success');
    },
    onShare(type) {
      let url = this.promotion.rec_url ? this.promotion.rec_url : window.location.origin;
      let text = this.$t('share_media_text');
      let share_url = ''
      const urls = {
        baidu: "http://cang.baidu.com/do/add?iu=@u&it=@t",
        buffer: "https://bufferapp.com/add?text=@t&url=@u",
        email: "mailto:?subject=@t&body=@u%0D%0A@d",
        evernote: "https://www.evernote.com/clip.action?url=@u&title=@t",
        facebook: "https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h",
        flipboard: "https://share.flipboard.com/bookmarklet/popout?v=2&url=@u&title=@t",
        hackernews: "https://news.ycombinator.com/submitlink?u=@u&t=@t",
        instapaper: "http://www.instapaper.com/edit?url=@u&title=@t&description=@d",
        line: "http://line.me/R/msg/text/?@t%0D%0A@u%0D%0A@d",
        linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=@u",
        messenger: "fb-messenger://share/?link=@u",
        odnoklassniki: "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=@u&st.comments=@t",
        pinterest: "https://pinterest.com/pin/create/button/?url=@u&media=@m&description=@t",
        pocket: "https://getpocket.com/save?url=@u&title=@t",
        quora: "https://www.quora.com/share?url=@u&title=@t",
        reddit: "https://www.reddit.com/submit?url=@u&title=@t",
        skype: "https://web.skype.com/share?url=@t%0D%0A@u%0D%0A@d",
        sms: "sms:?body=@t%0D%0A@u%0D%0A@d",
        stumbleupon: "https://www.stumbleupon.com/submit?url=@u&title=@t",
        telegram: "https://t.me/share/url?url=@u&text=@t%0D%0A@d",
        tumblr: "https://www.tumblr.com/share/link?url=@u&name=@t&description=@d",
        twitter: "https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu",
        viber: "viber://forward?text=@t%0D%0A@u%0D%0A@d",
        vk: "https://vk.com/share.php?url=@u&title=@t&description=@d&image=@m&noparse=true",
        weibo: "http://service.weibo.com/share/share.php?url=@u&title=@t&pic=@m",
        whatsapp: "https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d",
        wordpress: "https://wordpress.com/press-this.php?u=@u&t=@t&s=@d&i=@m",
        xing: "https://www.xing.com/social/share/spi?op=share&url=@u&title=@t",
        yammer: "https://www.yammer.com/messages/new?login=true&status=@t%0D%0A@u%0D%0A@d",
      }
      if (urls[type]) {
        share_url = urls[type];
      }
      if (share_url) {
        share_url = share_url.replace('@u', encodeURIComponent(url))
        .replace('@t', encodeURIComponent(text))
        .replace('@d', '');
        window.open(share_url);
      }
    },
    async getRechargeRebate(){
      const {data:res} = await getRechargeRebate_api();
      if(res && res.code == 1) this.data = res.data
    },
    async getTeamReport() {
      const {data:res} = await team_report_api()
      if (res && res.code == 1) this.report = res.data;
    },
    async getTeamPromotion() {
      const {data:res} = await team_promotion_api({type: 0})
      if (res && res.code == 1) this.promotion = res.data;
    },
  },
  mounted(){
    this.getRechargeRebate()
    this.getTeamReport()
    this.getTeamPromotion()
  }
}
</script>

<style src="@/assets/css/overview.less" lang="less" scoped></style>
