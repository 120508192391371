<template>
  <div>
    <h1 class="page-title">{{$t("团队中心")}}</h1>
    <myTabs :list="['Overview','报酬']" @change="i=>active=i" class="tabs"></myTabs>

    <!-- 总览 -->
    <overview v-if="active==0"></overview>

    <!-- 报酬 -->
    <remuneration v-if="active==1"></remuneration>
  </div>
</template>

<script>
import myTabs from "@/components/myTabs.vue";
import overview from "@/views/teamCenter/child/overview.vue";
import remuneration from "@/views/teamCenter/child/remuneration.vue";
export default {
  data(){
    return {
      active:0
    }
  },
  components:{myTabs,overview,remuneration},
}
</script>

<style src="@/assets/css/teamCenter.less" lang="less" scoped></style>