<template>
  <div>
    <div class="desc" v-if="$t('报酬介绍')!='报酬介绍'">{{ $t("报酬介绍") }}</div>
    <!-- 骨架屏 -->
		<el-skeleton :rows="10" :count="1" animated  v-if="skeletonShow">
      <template slot="template">
        <div class="item skeleton" v-for="item in 6" :key="item">
          <div class="content-box">
            <el-skeleton-item variant="image"  class="item-cover"/>
            <div class="pro-box">
              <div class="pro-title">
                <el-skeleton-item variant="text" />
              </div>
              <div class="progress-box">
                <el-skeleton-item variant="text"/>
              </div>
            </div>
          </div>
          <div class="button-box">
            <el-skeleton-item variant="text" class="btn-title"/>
            <el-skeleton-item variant="text"/>
          </div>
        </div>
      </template>
    </el-skeleton>  

    <div class="item" v-for="(item,index) in rewardlist" :key="index" v-else>
      <div class="content-box">
        <img :src="item.icon" class="item-cover">
        <div class="pro-box">
          <div class="pro-title">
            <div>{{$t(item.name)}} <span class="num">{{ item.stratum_number }}</span></div>
            <div class="m-hide"><span class="num">{{ total_num }}</span>/{{ item.stratum_number }}</div>
          </div>
          <div class="progress-box">
            <el-progress class="progress" :text-inside="true" :stroke-width="13" :percentage="parseInt(total_num / item.stratum_number * 100)" define-back-color="#3D7C69" text-color="rgba(0,0,0,0)" color="#FFDE89"></el-progress>
            <div class="pc-hide progress-num"><span class="num">{{ total_num }}</span>/{{ item.stratum_number }}</div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <div class="btn-title">{{ item.stratum_bonus }}</div>
        <el-button class="btn-yellow" size="small" v-if="item.is_finish" :disabled="item.is_receive > 0" @click="getReceiveInviteRebate(item)">
          <span v-if="item.is_receive == 1">{{$t('已领取')}}</span>
          <span v-else-if="item.is_receive == 2">{{$t('待审核')}}</span>
          <span v-else>{{$t('领取')}}</span>
        </el-button>
        <el-button class="btn-yellow" size="small" v-else disabled>{{$t('进行中')}}</el-button>
      </div>
    </div>

    
  </div>
</template>

<script>
import {getRemuneration_api,get_receiveInviteRebate_api} from "@/api/user";
export default {
  data() {
			return {
				isangent: true,
				rewardlist: [],
				total_num: 0,
				skeletonShow: true,
			}
		},
		methods: {
			async getRemuneration() {
				const res = await getRemuneration_api()
        this.skeletonShow = false
        if (res && res.data.code == 1) {
          this.rewardlist = res.data.data.list;
          this.total_num = res.data.data.total_num
        }
			},
			async getReceiveInviteRebate(item) {
				const receId = item.id
				let data = {
					id: receId
				};
				await get_receiveInviteRebate_api(data).then(res => {
					if (res && res.data.code == 1) {
						this.successTips(res.data.msg);
						item.is_receive = 1
						this.$forceUpdate()
					} else {
						this.errorTips(res.data.msg);
					}
				});
			},
		},
		mounted() {
			this.getRemuneration();
		},
}
</script>

<style src="@/assets/css/remuneration.less" lang="less" scoped></style>
